<template>
  <div>
    <div>
      <h3 class="card-label m-0">
        {{ $t('quality_inspections.quality_inspection') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('employees.basic_information')" @click="toggleTab('basic_information')" active>

          <div class="row">
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('quality_inspections.name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control"
                           :class="validation && validation.name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('quality_inspections.validator_user') }}<span class="text-danger">*</span></label>
                    <div class="input-group mb-3">
                      <multiselect
                          v-model="user"
                          :placeholder="$t('quality_inspections.validator_user')"
                          :class="validation && validation.validator_user_id ? 'is-invalid' : ''"
                          label="name"
                          track-by="id"
                          :options="users"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getUsers($event)">
                      </multiselect>
                      <div class="input-group-prepend">
                        <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i
                            class="fa fa-plus"
                            style="padding: 0"></i></a>
                      </div>
                    </div>
                    <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('quality_inspections.validator_user') }}.</span>
                    <span v-if="validation && validation.validator_user_id"
                          class="fv-plugins-message-container invalid-feedback">
                                      {{ validation.validator_user_id[0] }}
                                  </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('quality_inspections.validation_date') }}</label>
                    <input type="date" v-model="data.validation_date" class="form-control"
                           :class="validation && validation.validation_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.validation_date"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.validation_date[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mt-10 mb-10">
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>
                      {{ $t('quality_inspections.is_active') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-tabs content-class="mt-3" class="nav-custom-link">
            <b-tab :title="$t('notes')">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('quality_inspections.description') }}</label>
                    <textarea v-model="data.description" class="form-control"
                              :class="validation && validation.description ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.description"
                          class="fv-plugins-message-container invalid-feedback">
                                {{ validation.description[0] }}
                            </span>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>


        </b-tab>
        <b-tab :title="$t('employees.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">
          <div class="card card-custom">
            <div class="card-body">
              <attachments :quality-inspection-id="idEditing"></attachments>
            </div>
          </div>
        </b-tab>
      </b-tabs>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";

export default {
  name: "Form-quality-inspection",
  components: {'attachments': Attachments},
  data() {
    return {
      mainRoute: 'manufacturers/quality_inspections',
      mainRouteDependency: 'base/dependency',

      tab_name: 'basic_information',
      data: {
        name: null,
        validator_user_id: null,
        validation_date: null,
        description: null,
        is_active: true,
      },
      user: null,
      users: [],

      idEditing: this.$route.params.id,
      isEditing: false,

      validation: null,

    };
  },
  watch: {
    user: function (val) {
      if (val)
        this.data.validator_user_id = val.id;
      else
        this.data.validator_user_id = null;
    },

  },
  methods: {
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {

      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'quality-inspections.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'quality-inspections.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {

      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.validator_user_id = response.data.data.validator_user_id;
        this.data.validation_date = response.data.data.validation_date;
        this.data.description = response.data.data.description;
        this.data.is_active = response.data.data.is_active;

        this.user = response.data.data.validator_user;

      });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.quality_inspections"),
      route: '/manufactories/quality-inspections'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    if (this.idEditing) {
      this.getData();
    }

  },
}
</script>

<style scoped>

</style>