<template>
    <div class="form-group row mt-8">

        <div class="col-lg-6 mb-5">
            <label>{{$t('quality_inspections.name')}}</label>
            <input v-model="data.name" type="text" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
            <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.name[0] }}
                </span>
        </div>
        <div class="col-lg-6 mb-5">
            <label>{{$t('quality_inspections.attachment')}}</label>
            <upload-file
                    @file="listenerAttachment"
                    :inner-id="'attachment'"
                    :placeholder="$t('quality_inspections.upload_attachment')"
                    :upload="dir_upload"
                    :start-link="'base'"
                    v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
            </upload-file>
            <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                {{ validation.attachment[0] }}
            </span>
        </div>
        <div class="col-lg-6 mt-10 ">
            <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{$t('quality_inspections.is_active')}}</b-form-checkbox>
        </div>

        <div class="col-lg-12 mb-8 text-center">
            <button type="button" class="btn btn-primary m-2" @click="save">{{ $t('save') }}</button>
        </div>
        <div class="col-lg-12 mb-5 mt-15">
            <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="tableAttachment">
                <template slot="status" slot-scope="props">

                    <b-form-checkbox size="lg"
                                     @change="changeStatus(props.row.id, props.row.is_active)"
                                     v-model="props.row.is_active"
                                     :name="'check-button'+props.row.id"
                                     switch :key="props.row.id">
                    </b-form-checkbox>
                </template>
                <template slot="attachment_url" slot-scope="props">
                    <a target="_blank" :href="props.row.attachment_url" class="btn btn-link" >{{ $t('download') }}</a>
                </template>
                <template slot="actions" slot-scope="props">
                    <v-icon small class="mr-2 text-info" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                    <v-icon small class="text-danger"  color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>
                </template>
            </v-server-table>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";

    export default {
        name: "Attachments",
        props:['qualityInspectionId'],
        data() {
            return {
                mainRoute: 'manufacturers/quality_attachments',
                routeChangeStatus: 'manufacturers/quality_attachment/change-status',
                dir_upload: 'quality_inspection',

                data: {
                    quality_inspection_id: null,
                    name: null,
                    attachment: null,
                    is_active: false,
                },
                attachment_url: null,
                validation: null,
                reloadUploadAttachment: true,
                columns: ['name', 'attachment_url', 'status', 'actions'],
                idEditing: null,
            };
        },
        watch:{
            qualityInspectionId: function (val) {
                if (val){
                    this.data.quality_inspection_id = val;
                }
            }
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('quality_inspections.name'),
                        attachment_url: that.$t('quality_inspections.attachment'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },

                    filterByColumn: false,
                    customFilters: [],
                    filterable: false,
                    orderBy: {'column': 'id'},
                    sortable: [],
                    alwaysShowPerPageSelect: false,
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            // filter: data.query,
                            quality_inspection_id: that.qualityInspectionId,
                        }
                        return ApiService.query(that.mainRoute, {..._params})

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    is_active: (status ? 1 : 0),
                }).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },
            getFetch() {
                this.$refs.tableAttachment.refresh();
            },
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
                    ...this.data,
                })
                    .then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.afterSave();
                        this.getFetch();
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },
            editItem(item) {
                this.idEditing = item.id;
                this.reloadUploadAttachment = false;
                ApiService.get(this.mainRoute + "/" + item.id).then((response) => {
                    this.isEditing = true;
                    this.data.quality_inspection_id = response.data.data.quality_inspection_id;
                    this.data.name = response.data.data.name;
                    this.data.attachment = response.data.data.attachment;
                    this.data.is_active = response.data.data.is_active;
                    this.attachment_url = response.data.data.attachment_url;
                    this.reloadUploadAttachment = true;
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            afterSave() {
                this.attachment_url = null;
                this.reloadUploadAttachment = false;
                this.data = {

                    quality_inspection_id: this.qualityInspectionId,
                    name: null,
                    attachment: null,
                    is_active: false,
                };

                setTimeout(()=>{
                    this.reloadUploadAttachment = true;
                },100)
                this.idEditing = null;
                this.isEditing = false;
            },
            listenerAttachment(event) {
                if (event) {
                    this.attachment_url = event.pathDB;
                    this.data.attachment = event.name;
                }
            },
        },
        mounted() {
            this.data.quality_inspection_id = this.qualityInspectionId;
        },
    }
</script>

<style scoped>

</style>